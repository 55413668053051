		
<template>
	<div class="outer-wrapper">
		<div class="success-or-fail">
			
			<div id="top-nav"></div>
			
			<div class="inner-wrapper">

				<div class="heading-section">
					<h1 class="heading3">{{ title }}</h1>
				</div>
				
				<div class="img-container">
					<img v-if="success" src="@/assets/tick-icon.png" alt="tick" width="60px" />
					<img v-else src="@/assets/alert-icon.png" alt="tick" width="60px" />
				</div>
				<div class="caption">{{ mainText }}</div>
				<router-link v-if="this.$route.query.redirect" :to="this.$route.query.redirect">
					<button class="button">Continue where you left off</button>
				</router-link>
				<router-link :to="actionButtonRoute">
					<button class="button">{{ actionButtonText }}</button>
				</router-link>

			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		success: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: "Success!"
		},
		mainText: {
			type: String,
			default: "Operation completed."
		},
		actionButtonRoute: {
			type: Object,
			default: {
				name: 'login'
			}
		},
		actionButtonText: {
			type: String,
			default: "Continue",
		}
	},
};
</script>	

<style scoped>
	.success-or-fail {
		text-align: center;
	}
	.success-or-fail .img-container {
		margin: auto;
	}
	.success-or-fail .heading3 {
		padding-bottom: 42px;
	}
	.success-or-fail .caption {
		padding: 40px 60px 60px 60px;
	}
	.success-or-fail .button {
		margin-bottom: 10px;
	}
</style>
		